form {
  .form-group {
    .form-control,
    .ck-editor {
      margin-top: 8px;
    }
    label {
      color: #84828e;
      font-weight: bold;
    }
    &.has-error {
      .form-control {
        border-color: rgb(255, 107, 114);
      }
      .ant-select-selector {
        border-color: rgb(255, 107, 114);
      }
    }
  }
}
