$colorPrimary: #fc000c;

@import './form.scss';

body {
  margin: 0px;
}
.App{
  min-height: 100vh;
  background: #ffffff;
}
.total-widget {
  .ant-card {
    height: 240px;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.btn-upload-file {
  border: 1px solid #ececec;
  color: rgb(69, 85, 96);
  background-color: white;
  padding: 7px 20px;
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-container input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.changeStatus {
  height: 14px !important;
  min-width: 30px !important;

  .ant-switch-handle {
    width: 10px !important;
    height: 10px !important;
  }
}


.changeStatus.ant-switch-checked .ant-switch-handle {
  left: calc((100% - 10px) - 2px) !important;
}

.code-column {
  position: sticky;
  left: 200px !important;
}

//.ant-pagination-options {
//  display: none !important;
//}

.table-staff .ant-table-tbody > tr > td {
  padding: 10px 16px !important;
}

.table-staff .ant-table-measure-row {
  display: none;
}

#staffForm .ant-form-item {
  margin-bottom: 10px !important;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 33px !important;
  height: 33px !important;
}

.table-salary-structure, .table-work {
  .ant-table-sticky-header {
    top: 8% !important;
  }
}

.today-task-table {
  table {
    visibility: initial !important;

    tr {
      th:nth-child(1), td:nth-child(1) {
        width: 60px;
      }

      th:nth-child(2), th:nth-child(3), th:nth-child(4),
      td:nth-child(2), td:nth-child(3), td:nth-child(4) {
        width: 250px;
      }
    }
  }
}

.table-dashboard {
  .ant-table-body {
    table {
      height: 300px;
    }
  }
}

.ant-table-header > table > thead > tr > th, .ant-table-body > table > tbody > tr > td {
  border-right: none !important;
}

.table-salary-structure {
  .ant-table-header > table > thead > tr > th, .ant-table-body > table > tbody > tr > td {
    border-right: 1px solid #e6ebf1 !important;
  }
}

.warning {
  background: #ffc107;
  color: #fff;
}

.table-list-global-config {
  th, td {
    text-align: center !important;
  }
}

.ant-input-disabled, .ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-input > input-disabled, .ant-picker-input > input[disabled] {
  color: #000 !important;
}

.color-text-app {
  color: $colorPrimary;
}

.btn-delete-row {

}

.ant-menu-item-group-title {
  font-size: 15px !important;
}

.w-100 {
  width: 100%;
}

.formFilter {

  .ant-btn-primary {
    background: $colorPrimary !important;
    margin-top: 20px;
  }

  .w-100 {
    width: 100%;
  }
}

.number-task {
  font-size: 35px;
}

.table-salary th, .table-salary td {
  font-size: 16px;
}

.modal-form {
  .ant-btn-primary {
    margin-top: 20px;
  }
}

.popupForm {
  width: 1100px !important;
  top: 20px !important;

  .ant-modal-header {
    background: $colorPrimary;
    text-align: center;
    text-transform: uppercase;

    .ant-modal-title {
      font-size: 18px;
      color: #fff !important;
    }
  }

  .overTimeDetail {
    .ant-modal-footer {
      display: none !important;
    }
  }

  .ant-modal-close-x {
    color: #fff !important;
  }

  .ant-modal-footer {
    text-align: center !important;
    border-top: none !important;

    .ant-btn {
      border: 1px solid $colorPrimary;
    }

    .ant-btn-primary {
      background: $colorPrimary !important;
    }
  }
}

.block-table-input {
  margin-bottom: 24px;

  .ant-input, .ant-picker, .ant-select-selector {
    border-radius: 0 !important;
  }

  thead td {
    border: 1px solid #ececec;
    width: 16.66%;
    height: 45px;
    padding: 10px;
  }

  tbody td {
    border: 1px solid #ececec;
    width: 16.66%;
  }

  thead, tbody {
    .ant-input, .ant-select-selector, .ant-picker, .ant-select {
      border: none !important;
      width: 100%;
    }
  }

  .ant-form-item {
    width: 100%;
    display: inline-block;
    margin-bottom: 0 !important;

    .ant-form-item-label {
      width: 30%;
      float: left;
      height: 40px;
      line-height: 38px;
      border: 1px solid #ececec;
      padding: 0 0 0 10px;

      label {
        font-weight: 300 !important;
      }
    }

    .ant-form-item-control {
      width: 70%;
      float: left;
    }
  }
}

.button-primary-color {
  border: none;
  outline: none;
  color: #fff !important;

}

.app-content {
  background: #f2f2f7;
}

.card-dashboard {
  border: 1px solid rgba(64, 128, 247, 0.4);

  .ant-card-body {
    padding: 8px 16px;


  }
}

.card-worklist {
  cursor: pointer;

  & .box-active-number {
    opacity: 0.8;
  }




  &.active .box-active-text {

    font-weight: 500;
  }
}

.collapse-filter {
  .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-collapse-header {
    padding: 0 20px !important;
  }

  .ant-collapse-arrow {
    left: 0 !important;
    padding: 0
  }
}

.bg-child-payroll {
  background-color: #8497884a !important;
}

.bg-isDefault {
  background-color: #b958584a !important;
}

.ant-table-row-level-0 {
  td {
    padding: 10px 16px !important;
  }
}

.table-work {
  .ant-table-row-level-0 {
    td {
      padding: 10px 16px;
    }
  }
}

.table-work .ant-table-row-level-0:nth-child(2n) {
  .ant-table-cell-fix-left {
    background: #e9eef8;
  }
}

.table-work .ant-table-row-level-0:nth-child(2n):hover {
  .ant-table-cell-fix-left {
    background: rgb(250, 250, 250);
  }
}

.table-work .ant-table-row-level-0:nth-child(2n + 1):hover {
  .ant-table-cell-fix-left {
    background: rgb(250, 250, 250);
  }
}

.table-master, .table-work {
  .ant-table-thead > tr > th {
    background: $colorPrimary;
    color: white;
  }

  .ant-table-tbody {
    tr:nth-child(2n) {
      background: #e9eef8;
    }
  }

  .table-border-dot {
    .ant-table-thead > tr > th {
      background: #ffffff;
      color: unset;
      border-bottom-style: dashed;
      border-width: 2px;
    }

    .ant-table-tbody {
      tr:nth-child(2n) {
        background: #ffffff;
      }
    }

    tr > td {
      border-bottom-style: dashed;
      border-width: 2px;

      .ant-avatar {
        min-width: 32px;
      }

      .trip {
        margin: 0 auto;
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        &.public {
          background: #3e82f7;
        }

        &.private {
          background: #fa8c16;
        }

        &.total {
          background: #ffc107;
        }
      }
    }
  }

  .table-group-header {
    thead {
      tr {
        &:first-child {
          th {
            background: #f8e9e9;
            color: #bc3232;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .ant-table-cell-scrollbar {
    box-shadow: none;
  }
}

.border-dashed {
  border-style: dashed !important;
  border-width: 2px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.row-work {
  .bg-value {
    background: #f4f6fb;
  }
}

.modal-app {
  .ant-modal-header {
    background: $colorPrimary;
  }

  .header-modal-app {
    color: white;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

  .ant-modal-footer {
    background: #f4f6fb;
  }

  // .footer-modal-app {
  // }
  .ant-modal-close-x {
    color: white;
  }
}

.table-salary {
  table,
  th,
  td {
    border: 1px solid #dcdcdf;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 5px;
    text-align: left;
    line-height: 1.5;
  }

  .title {
    color: #ffffff;
    padding: 0.5rem 0rem;

    height: 40px;
  }

  &.kpi {
    tr {
      &:last-child {
        font-weight: bold;
      }
    }
  }

  &.income, &.deduct {
    tr:nth-child(2),
    tr:nth-child(3),
    tr:nth-child(4),
    tr:last-child {
      td {
        &:first-child {
          font-weight: bold;
        }
      }
    }

    tr:last-child {
      td {
        &:first-child {
          text-align: left;
        }
      }
    }
  }

  //&.deduct {
  //  tr:nth-child(2),
  //  tr:nth-child(n + 6) {
  //    td {
  //      &:first-child {
  //        font-weight: bold;
  //      }
  //    }
  //  }
  //
  //  tr:last-child {
  //    td {
  //      &:first-child {
  //        text-align: center;
  //      }
  //    }
  //  }
  //}
}

.ant-radio-inner {
  top: 4px !important;
}

.content-suggest-update {
  .ant-modal-close-x {
    color: unset;
  }
}

.review-info {
  margin-bottom: 0px !important;
}

.pl-12-percent {
  padding-left: 13%;
}

.confirm-modal .ant-modal-close-x {
  color: rgb(114, 132, 154) !important;
}

.button-is-disabled .ant-btn-sm:disabled .anticon {
  color: #ccc !important;
}

.btn-add-salary-composition {
  border-radius: 4px;
  padding: 7px;
  height: 35px;
}

.btn-filter-assignee {
  border-radius: 4px;
  padding: 5px 10px;
  height: 35px;
}

.filter-work-list-row .ant-form-item {
  margin-bottom: 0px !important;
}

.btn-remove-sc {
  padding-left: 0px !important;
  border: unset !important;
}

.btn-remove-sc:hover {
  background-color: unset !important;
}

.ct-pl-0 {
  padding-left: 0px;
}

.filter-work-field .ant-form-item {
  margin-bottom: 0px;
}

.card-worklist .ant-card-body {
  //padding: 10px 5px 3px;
  padding: 15px 5px 10px;
}

.ant-popover-placement-right .ant-popover-title {
  text-align: center;
  padding: 10px;
}

.assignee-tag {
  margin: 3px 10px 0 0;
}

.remove-assignee {
  padding: 0;
  height: 20px;
  background: unset;
  border: unset;
  position: absolute;
  top: 11px;
  left: -7px;
}

.nav-profile {
  width: 17.5rem;
}

.task-status-color .ant-tag-success {
  background: rgb(230, 245, 236);
}

.task-status-color .ant-tag-warning {
  background: #fffbe6;
}

.task-status-color .ant-tag-error {
  background: #fff2f0;
}

.task-status-color .ant-tag-processing {
  background: #e6f7ff;
}

.card-reason .ant-card-body {
  padding-top: 3px;
}

.input-reason input {
  height: 30px;
}

.switch-reason.ant-switch-checked {
  background-color: #21b573 !important;
}

.button-wrapper-input-file {
  position: relative;
  width: 100px;
  text-align: center;

  span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #1890ff;
    cursor: pointer;
    color: #fff;
    padding: 7px 0;
    border-radius: 3px;
  }
}

.upload-box {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 35px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    background: #dddddd;
  }
}

.salary-structure {
  ul {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.filter-work-card {
  .ant-card-body {
    padding: 10px 20px;
  }
}

@media only screen and (min-width: 1367px) {
  .ct-pl-0 {
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .filter-work-field {
    margin-bottom: 15px;
  }

  .filter-work-field-item {
    padding-left: 8px;
  }

  .filter-work-field-item:nth-child(2),
  .filter-work-field-item:nth-child(4) {
    padding-left: 0px !important;
  }

  .work-manager-box {
    margin-top: 15px;
  }

  .work-assignee-box {
    padding-left: 8px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .filter-work-field-item:nth-child(2),
  .filter-work-field-item:nth-child(4) {
    padding-left: 8px !important;
  }
}

.tree-time-fund {
  .ant-tree-switcher-leaf-line::after,
  .ant-tree-switcher-leaf-line::before {
    border-color: #333;
  }
}

.table-time-fund .ant-table-tbody tr:nth-child(2n) {
  background: unset !important;
}

.ant-tree.tree-time-fund.ant-tree-show-line {
  margin-left: -6px;
}

.tree-time-fund {
  //ant-tree-indent-unit-end
}

#apply_for {
  display: flex;
  justify-content: space-between;
}

.hours_change {
  .ant-select-selection-item {
    width: 10px;
    font-size: 20px;
  }
}

.spin-time-fund {
  .ant-spin-nested-loading > div {
    //margin: 15px 0;
  }
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.table-salary-structure {
  .ant-table-row-level-0:nth-child(2n) .ant-table-cell-fix-left {
    background: #e9eef8;
  }
}

.table-salary-structure .ant-table-row-level-0:nth-child(2n):hover {
  .ant-table-cell-fix-left {
    background: rgb(250, 250, 250);
  }
}

.table-salary-structure .ant-table-row-level-0:nth-child(2n + 1):hover {
  .ant-table-cell-fix-left {
    background: rgb(250, 250, 250);
  }
}

.side-nav {
  height: calc(100vh - 70px);
  position: fixed !important;
  top: 70px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
  z-index: 999;
  direction: ltr;
  background-color: rgb(255, 255, 255);
}

.ant-btn-secondary {
  background-color: rgba(0, 0, 0, 0.06);
}



.popupForm .ant-modal-header {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal .ant-modal-close {
  display: none;
}

.column-kanban {
  height: max-content;
  min-height: 500px;
  width: 100%;
}

.column-kanban::-webkit-scrollbar {
  width: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.do-it-column-kanban {
  background-color: #fff0f0;
}

.in-progress-column-kanban {
  background-color: #fef2e7;
}

.awaiting-review-column-kanban {
  background-color: #fffada;
}

.done-column-kanban {
  background-color: #f5ffe5;
}

.movable-item-kanban {
  border-radius: 10px;
  background-color: #fafdff;
  min-height: 100px;
  width: 98%;
  margin: 10px auto;
  padding: 10px;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  border: #d9d9d9 solid 1px;
}

.title-column-kanban {
  padding: 10px;
  margin: 5px 0px -5px 0px;
  background-color: white;
  border: #d9d9d9 solid 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.button-switch-table {
  //width: 90px;
  border-color: #bfbfbf;
  margin-left: 15px;
  height: 30px;
  cursor: pointer
}

.button-switch-last-table {
  margin: 0px 0px 0px 0px;
  @extend .button-switch-table;
}

.modal-app .ant-modal-header {

  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-card {
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;

  .header-card-item {
    cursor: grab;
    position: absolute;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 10px;

    &:before {
      content: "";
      height: 0.5px;
      width: 80px;
      background-color: rgb(255, 255, 255);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0px);
    }
  }
}

.column-kanban {
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
            left bottom,
            left top,
            color-stop(0.44, rgb(122,153,217)),
            color-stop(0.72, rgb(73,125,189)),
            color-stop(0.86, rgb(28,58,148)));
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }
}

.tour-notify {
  z-index: 999999 !important;
}
.tsmd{
  &:after{
    left: 0 !important
  }
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-form-item .ant-form-item-explain, :where(.css-dev-only-do-not-override-xu9wm8).ant-form-item .ant-form-item-extra {
  font-size: 10px;
}